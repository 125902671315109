const Contact = () => {
  return (
    <section id="contact" className="py-20 bg-secondary">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold text-primary mb-8">
          Get in Touch
        </h2>
        <p className="text-primary mb-12">
          Have questions or need more information? Fill out the form below, and
          we'll get back to you as soon as possible.
        </p>
        <form className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg">
          <div className="mb-6">
            <label htmlFor="name" className="block text-primary font-semibold mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="w-full px-4 py-2 border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
              placeholder="Your Name"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block text-primary font-semibold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-4 py-2 border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
              placeholder="Your Email"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="message" className="block text-primary font-semibold mb-2">
              Message
            </label>
            <textarea
              id="message"
              rows="5"
              className="w-full px-4 py-2 border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
              placeholder="Your Message"
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-primary text-secondary px-6 py-3 rounded-lg font-bold shadow-md hover:bg-accent hover:text-primary transition duration-300"
          >
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};
  
  export default Contact;