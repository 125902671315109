import { useState } from "react";
import services from "../data/services";

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (service) => setSelectedService(service);
  const closeModal = () => setSelectedService(null);

  return (
    <section id="services" className="py-20 bg-secondary px-6 sm:px-10 lg:px-20">
      <div className="container mx-auto text-center">
        {/* Nested Arrows */}
        <div className="flex justify-center mb-6">
          <img
            src={`${process.env.PUBLIC_URL}/seta.svg`}
            alt="Nested Arrows"
            className="w-[6rem] h-[6rem]"
          />
        </div>

        <h2 className="text-4xl text-black mb-12">
          Conheça as nossas opções de seguros:
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              onClick={() => openModal(service)}
              className="relative bg-white p-6 flex flex-col items-center justify-between transform transition hover:-translate-y-3 hover:shadow-2xl border-2 border-primary cursor-pointer h-full text-center rounded-lg"
            >
              {/* Icon */}
              <div className="flex-shrink-0 mb-4">
                <img
                  src={service.icon}
                  alt={`${service.title} icon`}
                  className="w-[8rem] h-[8rem] sm:w-[8rem] sm:h-[8rem]"
                />
              </div>

              {/* Title */}
              <h3 className="text-xl font-semibold text-accent mb-2">
                {service.title}
              </h3>

              {/* Description */}
              <p className="text-gray-600">{service.description}</p>

              {/* Persistent "Learn More" */}
              <div className="mt-6">
                <span className="text-sm text-primary font-semibold hover:text-accent transition absolute bottom-4 left-1/2 transform -translate-x-1/2">
                  Saiba Mais →
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedService && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 relative">
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-800 transition"
            >
              ✕
            </button>

            {/* Modal Content */}
            <div className="text-center">
              <div className="mb-4">
                <img
                  src={selectedService.icon}
                  alt={`${selectedService.title} icon`}
                  className="w-16 h-16 mx-auto"
                />
              </div>
              <h3 className="text-2xl font-bold text-primary mb-4">
                {selectedService.title}
              </h3>
              <p className="text-gray-600 mb-4">
                {selectedService.description}
              </p>
              <p className="text-gray-800">
                {selectedService.details ||
                  "Detailed information about this service will be displayed here."}
              </p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Services;
