import { useState, useEffect } from "react";
import { FaHandshake, FaInfoCircle, FaEnvelope } from "react-icons/fa";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.6 }
    );

    sections.forEach((section) => observer.observe(section));
    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-500 ${
        scrolled ? "shadow-md" : ""
      } bg-secondary h-24 lg:h-28`}
    >
      <div className="flex items-center justify-between h-full px-4 lg:px-12">
        {/* Logo */}
        <a href="#hero" className="flex items-center">
          <img
            src={`${process.env.PUBLIC_URL}/logo.svg`}
            alt="Company Logo"
            className="w-[16rem] h-[16rem] object-contain"
          />
        </a>

        {/* Navigation (Desktop) */}
        <nav className="hidden md:flex items-center justify-center space-x-16 lg:space-x-20">
          <a
            href="#services"
            className={`relative flex items-center text-xl lg:text-2xl font-semibold transition-all ${
              activeSection === "services"
                ? "text-primary"
                : "text-gray-500 hover:text-primary"
            }`}
          >
            <FaHandshake className="text-xl lg:text-2xl mr-2" />
            <span>Serviços</span>
            {activeSection === "services" && (
              <span className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary"></span>
            )}
          </a>

          <a
            href="#about-us"
            className={`relative flex items-center text-xl lg:text-2xl font-semibold transition-all ${
              activeSection === "about-us"
                ? "text-primary"
                : "text-gray-500 hover:text-primary"
            }`}
          >
            <FaInfoCircle className="text-xl lg:text-2xl mr-2" />
            <span>Sobre Nós</span>
            {activeSection === "about-us" && (
              <span className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary"></span>
            )}
          </a>

          <a
            href="#contact"
            className={`relative flex items-center text-xl lg:text-2xl font-semibold transition-all ${
              activeSection === "contact"
                ? "text-primary"
                : "text-gray-500 hover:text-primary"
            }`}
          >
            <FaEnvelope className="text-xl lg:text-2xl mr-2" />
            <span>Contacto</span>
            {activeSection === "contact" && (
              <span className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary"></span>
            )}
          </a>
        </nav>

        {/* Mobile Menu Toggle */}
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="md:hidden focus:outline-none"
        >
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>

      {/* Mobile Dropdown Menu */}
      {menuOpen && (
        <div className="absolute top-full left-0 w-full bg-secondary shadow-lg md:hidden">
          <nav className="flex flex-col items-center py-4 space-y-4">
            <a
              href="#services"
              onClick={() => setMenuOpen(false)}
              className={`relative flex items-center text-lg font-medium transition-all ${
                activeSection === "services"
                  ? "text-primary"
                  : "text-gray-500 hover:text-primary"
              }`}
            >
              <FaHandshake className="text-lg mr-2" />
              <span>Services</span>
            </a>
            <a
              href="#about-us"
              onClick={() => setMenuOpen(false)}
              className={`relative flex items-center text-lg font-medium transition-all ${
                activeSection === "about-us"
                  ? "text-primary"
                  : "text-gray-500 hover:text-primary"
              }`}
            >
              <FaInfoCircle className="text-lg mr-2" />
              <span>About Us</span>
            </a>
            <a
              href="#contact"
              onClick={() => setMenuOpen(false)}
              className={`relative flex items-center text-lg font-medium transition-all ${
                activeSection === "contact"
                  ? "text-primary"
                  : "text-gray-500 hover:text-primary"
              }`}
            >
              <FaEnvelope className="text-lg mr-2" />
              <span>Contact</span>
            </a>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
