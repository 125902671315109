import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import policies from "../data/policyData"; // Import shared data

const Footer = () => {
  const [activeModal, setActiveModal] = useState(null);

  const openModal = (type) => setActiveModal(type);
  const closeModal = () => setActiveModal(null);

  const activePolicy = policies.find((policy) => policy.type === activeModal);

  return (
    <footer className="bg-accent text-secondary py-10 relative">
      <div className="container mx-auto flex flex-wrap justify-between items-start space-y-6 md:space-y-0">
        {/* Company Info */}
        <div className="w-full md:w-1/2">
          <h3 className="text-xl font-bold mb-4">PJTL Seguros</h3>
          <p>
            Providing peace of mind with tailored insurance plans. Let us
            protect what matters most in your life.
          </p>
        </div>

        {/* Social Media */}
        <div className="w-full md:w-1/2 flex flex-col items-end">
          <ul className="flex space-x-6 mb-4">
            <li>
              <a href="#facebook" className="text-secondary hover:text-accent transition">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
            </li>
            <li>
              <a href="#twitter" className="text-secondary hover:text-accent transition">
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
            </li>
            <li>
              <a href="#linkedin" className="text-secondary hover:text-accent transition">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </li>
          </ul>

          {/* Policy Links */}
          <ul className="flex space-x-4 text-sm">
            {policies.map((policy) => (
              <li key={policy.type}>
                <button
                  onClick={() => openModal(policy.type)}
                  className="hover:text-accent transition"
                >
                  {policy.title}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Modal */}
      {activeModal && activePolicy && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-secondary text-primary rounded-lg p-8 max-w-lg w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{activePolicy.title}</h2>
              <button onClick={closeModal} className="hover:text-accent">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <p>{activePolicy.content}</p>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;