const policies = [
    {
      type: "privacy",
      title: "Privacy Policy",
      content: "This is the content for the Privacy Policy. Replace this with your actual text.",
    },
    {
      type: "terms",
      title: "Terms of Service",
      content: "This is the content for the Terms of Service. Replace this with your actual text.",
    },
    {
      type: "cookies",
      title: "Cookies Policy",
      content: "This is the content for the Cookies Policy. Replace this with your actual text.asdasdasd",
    },
  ];
  
  export default policies;