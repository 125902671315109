import { useState, useEffect } from "react";

const Hero = () => {
  const images = [
    `${process.env.PUBLIC_URL}/1.jpg`,
    `${process.env.PUBLIC_URL}/2.jpg`,
    `${process.env.PUBLIC_URL}/3.jpg`,
    `${process.env.PUBLIC_URL}/4.jpg`,
    `${process.env.PUBLIC_URL}/5.jpg`,
    `${process.env.PUBLIC_URL}/6.jpg`,
  ];

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section id="hero" className="relative w-full h-screen overflow-hidden">
      {/* Background Images */}
      <div className="absolute inset-0 w-full h-full">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Background ${index + 1}`}
            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${
              index === currentImage ? "opacity-100" : "opacity-0"
            }`}
            style={{
              objectPosition: "center center", // Centers the image for desktop
              "@media (max-width: 768px)": {
                objectPosition: "top", // Focuses on the top of the image for mobile
              },
            }}
          />
        ))}
      </div>

      {/* Button */}
      <div className="absolute left-4 bottom-4 md:left-20 md:bottom-10 lg:left-24 lg:bottom-16 z-10">
        <a
          href="#services"
          className="bg-primary text-secondary px-8 py-3 md:px-12 md:py-5 text-sm md:text-lg font-extrabold tracking-tighter rounded-md shadow-lg hover:bg-accent hover:text-primary transition duration-300 transform hover:scale-105"
        >
          Conheça os nossos seguros
        </a>
      </div>
    </section>
  );
};

export default Hero;
