import Header from "../components/Header";
import Hero from "../components/Hero";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import CookiesBanner from "../components/CookiesBanner";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Services />
      <Contact />
      <Footer />
      <CookiesBanner />
    </>
  );
};

export default Home;