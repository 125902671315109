import React, { useState, useEffect } from "react";
import policies from "../data/policyData";

const CookiesBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [activePolicy, setActivePolicy] = useState(null); 
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const consent = localStorage.getItem("cookiesConsent");
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = () => {
    const allAccepted = { necessary: true, analytics: true, marketing: true };
    localStorage.setItem("cookiesConsent", JSON.stringify(allAccepted));
    setPreferences(allAccepted);
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem("cookiesConsent", JSON.stringify(preferences));
    setShowPreferences(false);
    setShowBanner(false);
  };

  const handlePreferenceChange = (type) => {
    // Don't allow changing "necessary"
    if (type !== "necessary") {
      setPreferences((prev) => ({ ...prev, [type]: !prev[type] }));
    }
  };

  const handleOpenPolicy = (type) => {
    const selectedPolicy = policies.find((policy) => policy.type === type);
    setActivePolicy(selectedPolicy || null);
  };

  const handleClosePolicy = () => {
    setActivePolicy(null);
  };

  return (
    <>
      {/* Cookies Banner */}
      {showBanner && (
        <footer className="bg-primary text-secondary p-4 fixed bottom-0 left-0 w-full z-50">
          <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
            <p className="text-sm text-center md:text-left">
              We use cookies to personalize content, analyze traffic, and improve your experience. Learn more about our{" "}
              <button
                onClick={() => handleOpenPolicy("cookies")}
                className="underline text-accent hover:text-secondary transition"
              >
                Cookies
              </button>
              ,{" "}
              <button
                onClick={() => handleOpenPolicy("privacy")}
                className="underline text-accent hover:text-secondary transition"
              >
                Privacy Policy
              </button>
              , and{" "}
              <button
                onClick={() => handleOpenPolicy("terms")}
                className="underline text-accent hover:text-secondary transition"
              >
                Terms of Use
              </button>
              .
            </p>
            <div className="flex space-x-4">
              <button
                onClick={handleAcceptAll}
                className="bg-secondary text-primary px-4 py-2 rounded-md shadow hover:bg-accent transition"
              >
                Accept All
              </button>
              <button
                onClick={() => setShowPreferences(true)}
                className="border border-secondary text-secondary px-4 py-2 rounded-md shadow hover:bg-accent-light hover:text-primary transition"
              >
                Customize
              </button>
            </div>
          </div>
        </footer>
      )}

      {/* Preferences Modal */}
      {showPreferences && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-secondary text-primary rounded-lg shadow-lg p-6 max-w-lg w-full relative">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Cookie Preferences</h2>
              <button
                onClick={() => setShowPreferences(false)}
                className="hover:text-accent"
              >
                ✕
              </button>
            </div>
            <div className="space-y-4">
              {/* Essential Cookies */}
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Essential Cookies (Required)</span>
                <ToggleSwitch
                  isOn={preferences.necessary}
                  onToggle={() => {}}
                  disabled
                />
              </div>

              {/* Analytics Cookies */}
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Analytics Cookies</span>
                <ToggleSwitch
                  isOn={preferences.analytics}
                  onToggle={() => handlePreferenceChange("analytics")}
                />
              </div>

              {/* Marketing Cookies */}
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Marketing Cookies</span>
                <ToggleSwitch
                  isOn={preferences.marketing}
                  onToggle={() => handlePreferenceChange("marketing")}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setShowPreferences(false)}
                className="text-gray-600 px-4 py-2 rounded-md hover:bg-gray-200 transition"
              >
                Cancel
              </button>
              <button
                onClick={handleSavePreferences}
                className="bg-accent text-primary px-4 py-2 rounded-md hover:bg-accent-light transition"
              >
                Save Preferences
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Policy Modal */}
      {activePolicy && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-secondary text-primary rounded-lg shadow-lg p-6 max-w-lg w-full relative">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{activePolicy.title}</h2>
              <button
                onClick={handleClosePolicy}
                className="hover:text-accent"
              >
                ✕
              </button>
            </div>
            <p className="text-sm text-gray-600">{activePolicy.content}</p>
          </div>
        </div>
      )}
    </>
  );
};

/**
 * ToggleSwitch Component
 * A simple toggle switch that can be clicked to change state.
 * 
 * Props:
 * - isOn: boolean - whether the switch is on or off
 * - onToggle: function - callback to handle toggling
 * - disabled: boolean - if true, no toggling occurs on click
 */
const ToggleSwitch = ({ isOn, onToggle, disabled = false }) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={`relative inline-block w-14 h-8 rounded-full transition-colors duration-200 ${
        isOn ? "bg-primary" : "bg-gray-300"
      } ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
      onClick={() => {
        if (!disabled) onToggle();
      }}
      onKeyDown={(e) => {
        if (!disabled && (e.key === "Enter" || e.key === " ")) onToggle();
      }}
    >
      <div
        className={`absolute top-1 left-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform duration-200 ${
          isOn ? "translate-x-6" : ""
        }`}
      ></div>
    </div>
  );
};

export default CookiesBanner;
