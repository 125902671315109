const services = [
  {
    title: "Seguro Multirriscos Habitação",
    description: "Com o seguro automóvel da Fidelidade, terá proteção completa para si, o seu veículo e os seus passageiros. Desde responsabilidade civil obrigatória até coberturas contra acidentes, furto ou danos naturais, temos a solução ideal para o seu dia a dia.",
    icon: `${process.env.PUBLIC_URL}/icones-02.svg`,
    details: `
      Benefícios:
        - Cobertura em caso de acidentes.
        - Proteção contra furto e vandalismo.
        - Assistência em viagem 24/7.

        Escolha o seguro que faz sentido para si. Fale connosco e receba uma simulação gratuita.
    `,
  },
  {
    title: "Seguro de Saúde",
    description: "Com o seguro de saúde da Fidelidade, terá acesso a uma vasta rede de médicos e hospitais, garantindo a melhor assistência para si e para a sua família. Cuidamos da sua saúde com planos ajustados às suas necessidades.",
    icon: `${process.env.PUBLIC_URL}/icones-03.svg`,
    details: `
     Benefícios:
      - Consultas e exames a preços reduzidos.
      - Internamentos e cirurgias cobertos.
      - Teleconsulta para conveniência máxima.

      Cuide de si e da sua família com o melhor seguro de saúde. Contacte-nos!

    `,
  },
  {
    title: "Seguro Automóvel",
    description: "Com o seguro automóvel da Fidelidade, terá proteção completa para si, o seu veículo e os seus passageiros. Desde responsabilidade civil obrigatória até coberturas contra acidentes, furto ou danos naturais, temos a solução ideal para o seu dia a dia.",
    icon: `${process.env.PUBLIC_URL}/icones-04.svg`,
    details: `
      Benefícios:
      - Cobertura em caso de acidentes.
      - Proteção contra furto e vandalismo.
      - Assistência em viagem 24/7.

      Escolha o seguro que faz sentido para si. Fale connosco e receba uma simulação gratuita.
    `,
  },
  {
    title: "Seguro Poupança e PPR",
    description: "Os seguros de poupança e PPR da Fidelidade permitem-lhe planear o futuro com tranquilidade. Seja para projetos de vida ou para a sua reforma, oferecemos soluções seguras e rentáveis.",
    icon: `${process.env.PUBLIC_URL}/icones-05.svg`,
    details: `
      Benefícios:
      - Taxas competitivas para maximizar o seu investimento.
      - Opções flexíveis para adaptação às suas metas.
      - Benefícios fiscais em PPR.

      Planeie o futuro com confiança. Peça mais informações.
    `,
  },
  {
    title: "Seguro Animal de Estimação",
    description: "Com o seguro para animais de estimação, assegure os melhores cuidados para o seu companheiro. Este seguro inclui assistência veterinária, responsabilidade civil e apoio em situações inesperadas, garantindo que o seu melhor amigo está sempre protegido.",
    icon: `${process.env.PUBLIC_URL}/icones-07.svg`,
    details: `
      Benefícios:
      - Taxas competitivas para maximizar o seu investimento.
      - Opções flexíveis para adaptação às suas metas.
      - Benefícios fiscais em PPR.

      Planeie o futuro com confiança. Peça mais informações.
    `,
  },
  {
    title: "Seguro de Acidentes de Trabalho",
    description: "O Seguro de Acidentes de Trabalho é obrigatório para empregadores e trabalhadores, garantindo proteção em caso de imprevistos no local de trabalho. Oferecemos soluções adaptadas às exigências de cada setor, para que todos trabalhem com confiança.",
    icon: `${process.env.PUBLIC_URL}/icones-08.svg`,
    details: `
     Descubra como proteger o seu animal. Peça uma simulação.

    `,
  },
  {
    title: "Seguro de Vida/Crédito",
    description: "Os seguros de vida e crédito protegem a sua família e os seus compromissos financeiros. Esteja preparado para imprevistos, com soluções que garantem estabilidade em momentos difíceis.",
    icon: `${process.env.PUBLIC_URL}/icones-09.svg`,
    details: `
      Benefícios:
      - Cobertura em caso de invalidez ou morte.
      - Soluções para créditos hipotecários.
      - Flexibilidade na escolha dos montantes.
    `,
  },
  {
    title: "Seguro de Acidentes Pessoais",
    description: "Os imprevistos acontecem, mas com o Seguro de Acidentes Pessoais estará preparado. Este seguro cobre despesas médicas, invalidez e outras necessidades resultantes de acidentes inesperados, para que possa viver o seu dia a dia com mais tranquilidade.",
    icon: `${process.env.PUBLIC_URL}/icones-06.svg`,
    details: `
     Garanta a sua segurança pessoal. Fale conosco.
    `,
  },
];

export default services;
